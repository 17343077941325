import React from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {classes} from './Footer.st.css';
import {InnerFooter} from '../../../common/components/InnerFooter/InnerFooter';

export const Footer = ({dataHook}: {dataHook: string}) => {
  const {
    paymentRequestSettingsStore: {
      paymentRequestSettings: {termsAndConditions, privacyPolicy, returnPolicy, contactUs, customPolicy, digitalPolicy},
    },
  } = useControllerProps();

  if (
    !termsAndConditions.enabled &&
    !privacyPolicy.enabled &&
    !returnPolicy.enabled &&
    !contactUs.enabled &&
    !customPolicy.enabled &&
    !digitalPolicy.enabled
  ) {
    return null;
  }

  return (
    <footer className={classes.footer} data-hook={dataHook}>
      <InnerFooter
        termsAndConditions={termsAndConditions}
        privacyPolicy={privacyPolicy}
        returnPolicy={returnPolicy}
        digitalPolicy={digitalPolicy}
        contactUs={contactUs}
        customPolicy={customPolicy}
        shouldShowDigitalItemPolicy={true}
        onPolicyClicked={() => {}}
      />
    </footer>
  );
};
